import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Capricorn.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Capricorn 
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/capricorn"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Capricorn </h4>
              </a>
              
            
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Capricorn </h1>
                <h2 className="text-sm md:text-base ml-4">Dec 22 - Jan 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-love");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-nature");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-man");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-traits");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-facts");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                  
            <p className="playfair text-black text-xl md:text-3xl">
                    About Capricorn
                  </p>
                  <br/>
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Sanskrit/Vedic name:
                  </p>
                  Makara
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Capricorn Meaning:
                  </p>{" "}
                  The Goat
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Type:
                  </p>{" "}
                  Earth-Cardinal-Negative
                  <br />
                  <br />
                  Capricorn Sign Capricorn, the upward climbing, always forward
                  moving Mountain Goat, represents the 10th Sign of the Zodiac.
                  Committed to responsibilities, Capricorn is a sign that
                  represents the work side of life. They are very focused on
                  achieving the most amount of work in the least amount of time
                  with the highest degree of quality. They are often calm and
                  appear slightly cold, meticulous, and dogged in their
                  persistence for quality and productivity.
                  <br />
                  <br />
                  They are incredibly focused on detail and quality and are
                  persistent and dogged in their pursuit of goals. Because
                  Capricorn is a fixed sign, they don't do a lot of casual or
                  socializing, and they can come across as a bit cold and
                  distant. However, once they get to know someone, they open up
                  and can be very loyal and devoted friends. They are reliable
                  and methodical in their approach and seek to achieve results
                  rather than simply busywork. They are hard workers and are
                  very good at making time for both the most important things
                  and what needs to be done. They are good planners and often
                  great at managing their time and calendars.
                  <br />
                  <br />
                  It is often the case, and it is admirable, particularly if
                  part of a designed strategy and system. However, the downside
                  is that some people born under the sign of Capricorn can come
                  across as cold and aloof, mainly when they are at work. They
                  are often so focused on quality, and productivity that they
                  forget to include the human touch and connection in their
                  day-to-day work.
                  <br />
                  <br />
                  Capricorns are creatures of habit, and they never stop working
                  until they've completed every last task on their to-do list.
                  Because of this workaholic streak, they can be a bit cold and
                  distant to others. However, once they get to know someone,
                  they are incredibly loyal and will defend their friends and
                  family with their lives.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    All About Capricorn
                  </p>
                  <br/>
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Ruling planet:
                  </p>{" "}
                  Saturn
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Element:
                  </p>{" "}
                  Earth
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Quality:
                  </p>{" "}
                  Cardinal (= activity)
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Symbol:
                  </p>{" "}
                  The Goat
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">The Symbol denotes:</p> Shyness, the the capacity to stay focused
                  and reach goals Characteristics: Kind, Feminine, Detached
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Noteworthy qualities:
                  </p>{" "}
                  Smart, Noble, Cautious, Helpful, Thoughtful, Diplomatic,
                  Profound, Cheerful, Ambitious, Focussed, Magnetic.
                  <br />
                  <br />
                  Undesirable personality traits: Shy, Jealous, Selfish,
                  Questionable, Authoritative, Gloomy, Prone to Depression and
                  Dissatisfaction. Birthstone: Blue Sapphire, Amethyst (The
                  gemstone is suggested considering Aries as the Ascendant/Lagna
                  Sign. To know your Ascendant/Lagna Sign, click here.)
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Birth Colours:
                  </p>{" "}
                  Black, Indigo
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Other lucky colors:
                  </p>{" "}
                  Puce, Silver, Ultramarine Blue
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lucky Numbers :
                  </p>{" "}
                  8, 17, 26, 35, 44, 53, 62
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Compatible Signs:
                  </p>{" "}
                  Taurus, Virgo
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Lucky Day:
                  </p>{" "}
                  Saturday
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Most profound need:
                  </p>{" "}
                  To manage, take charge and administrate
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">Quality most needed for balance:</p> Warmth, Spontaneity, a Sense
                  of Fun
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Detriment:{" "}
                  </p>
                  The Moon
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Exaltation:
                  </p>{" "}
                  Neptune
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Fall:{" "}
                  </p>
                  Jupiter
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Associated Flowers and Plants:
                  </p>{" "}
                  Evergreen, Sturdy Plants, Bamboo, Marigold, Mountain Flowers,
                  Wild Flowers
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Best Sign/s for marriage and/or partnerships:{" "}
                  </p>
                  Cancer
                  <br />
                  <br />
                  Anatomically Aquarius corresponds to Knee joints and the
                  patella bone joints; skeleton; knee muscles; nerves in the
                  knee; arteries in the knees; veins running through the knees.
                  <br />
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
